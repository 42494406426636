import icon from './icons';
import { InputClasses, InputOptions } from '../interface';

const autocompleteInput = (options: InputOptions): string => {
  return `
    <div class="${InputClasses.AUTO_COMPLETE_WRAPPER} ${options.name} ${InputClasses.AUTO_COMPLETE_WRAPPER_WIDGET}
  }">
      <div class="${InputClasses.BASE} ${InputClasses.AUTO_COMPLETE} ${options.name}">
          <div class="${InputClasses.AUTO_COMPLETE_INPUT_WRAPPER}">  
              <label for="${options.name}">
                <div class="${InputClasses.AUTO_COMPLETE_ICON_WRAPPER}">
                  ${icon('marker-new')}
                </div>
                <span class="${InputClasses.LABEL}">${options.label}</span>
                <div style="position: relative; width: 100%">
                  <input class="${InputClasses.AUTO_COMPLETE_INPUT} data-hj-whitelist" 
                  placeholder="${options.placeHolder}" autocomplete="off" autocorrect="off" spellcheck="false" 
                  id="${options.name}" name="${options.name}"/>
                  <div class="${InputClasses.FEEDBACK}" data-error-list="${options.error}" data-error-no-value="${
    options.error_no_value
  }"></div>
                </div>
              </label>
              <div class="${InputClasses.CLEAR}">
                  ${icon('cross')}
              </div>
              <div class="${InputClasses.WARNING_ICON}">
                  ${icon('warning')}
              </div>
            </div>
            
        <div class="${InputClasses.AUTO_COMPLETE_LIST_CONTAINER}"></div>
        <div class="${InputClasses.AUTO_COMPLETE_ZERO_RESULT}"></div>
     </div>
 </div>
  `;
};

export default autocompleteInput;
