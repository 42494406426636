import { GeoAutocomplete, GeoLocationDetails } from "@brenger/api-client";
import { Config } from "../../config";
import { logError } from "../../../src/utils/basics";
import { storeAutocompleteResults } from "../helpers/storage";
import { trackZeroResults } from "../helpers/track";
import { AutoCompleteOptions, InputClasses } from "../interface";
import { GooglePlacesSessionToken } from "./autocompleteInput";
import { getActiveLocale } from "../helpers/translations";

export const callAutocomplete = (options: AutoCompleteOptions): void => {
  const parent = options.inputParent;
  if (!parent) {
    return;
  }

  parent.classList.add(InputClasses.AUTO_COMPLETE_LOADING);
  const url = new URL(`${Config.GEO_API_URL}/v2/autocomplete`);

  fetch(url.toString(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "x-user-locale": getActiveLocale(),
    },
    body: JSON.stringify({
        q: options.text,
        session_token: GooglePlacesSessionToken
    })
  })
    .then(res => res.json())
    .then(response => {
      parent.classList.remove(InputClasses.AUTO_COMPLETE_LOADING);
      options.callback(response);
      storeAutocompleteResults(options.name, response);
      trackZeroResults(options.text, response);
    })
    .catch(error => {
      logError(error);
    });
};

export const fetchPlaceDetails = async (item: GeoAutocomplete): Promise<GeoLocationDetails | void> => {
  const url = new URL(`${Config.GEO_API_URL}/v2/autocomplete_select`);

  return await fetch(url.toString(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "x-user-locale": getActiveLocale(),
    },
    body: JSON.stringify({
      place_id: item.place_id,
      session_token: GooglePlacesSessionToken
    })
  })
    .then(res => res.json())
    .catch(error => {
      logError(error);
    });
};
