import { CustomEvents } from "../interface";
import { Config } from "../../config";
import { logError } from "../../../src/utils/basics";
import { UserMe } from "@brenger/api-client";

export const checkLoggedIn = (): void => {
  try {
    fetch(`${Config.API_URL}/am_i_logged_in`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.ok) {
          fetchUser();
        } else {
          handleUserError();
        }
      })
      .catch(error => {
        logError(error);
        handleUserError();
      });
  } catch (e) {
    handleUserError();
  }
};

const fetchUser = (): void => {
  try {
    fetch(`${Config.API_URL}/users/me`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        handleUserError();
        return;
      })
      .then(response => {
        handleUserSuccess(response);
      })
      .catch(error => {
        logError(error);
        handleUserError();
      });
  } catch (e) {
    handleUserError();
  }
};

const handleUserSuccess = (result: UserMe): void => {
  const event = new CustomEvent(CustomEvents.USER_UPDATED, {
    detail: {
      result,
    },
  });
  document.dispatchEvent(event);
};

const handleUserError = (): void => {
  const event = new CustomEvent(CustomEvents.USER_UPDATED, {
    detail: {
      result: {},
    },
  });
  document.dispatchEvent(event);
};
